import cx from 'classnames';
import { CSSProperties, useContext, useState } from 'react';
import { Keyboard, Navigation, Mousewheel } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import { Swiper, SwiperSlide } from 'swiper/react';

import { PageContext } from '../../context/PageContext';
import { IconLoader } from '../images/IconLoader';
import { SliderColorType } from './SliderOptions';

export type SliderProps = {
  slides?: React.ReactElement[];
  columns?: number | 'auto';
  gap?: number;
  controlsColor?: SliderColorType;
  slideStyle?: CSSProperties;
  className?: string;
  slideClassName?: string;
};

const controlsColorClasses: Record<SliderColorType, string> = {
  white:
    'border-white text-white disabled:bg-white/10 disabled:border-white/0 disabled:text-white/75',
  'action-base':
    'border-action-base text-action-base disabled:bg-neutral-95 disabled:border-neutral-95 disabled:text-neutral-50',
};

export const Slider = ({
  slides,
  columns,
  gap = 0,
  controlsColor,
  slideStyle,
  className,
  slideClassName,
}: SliderProps) => {
  const { config, locale } = useContext(PageContext);
  const translations = config.translations;

  const [prevEl, setPrevEl] = useState<HTMLElement | null>(null);
  const [nextEl, setNextEl] = useState<HTMLElement | null>(null);

  if (!slides?.filter(Boolean).length) return null;

  return (
    <div className={cx('relative', className)}>
      <Swiper
        spaceBetween={gap}
        slidesPerView={1}
        // slidesPerGroup={columns} // this sort of breaks dragging behaviour, removing it for now
        grabCursor={!slides?.some(({ props }) => props.href)}
        // centeredSlides={true}
        breakpoints={{
          640: {
            slidesPerView: 2,
          },
          768: {
            slidesPerView: 3,
          },
          1024: {
            slidesPerView: columns,
          },
        }}
        modules={[Mousewheel, Keyboard, Navigation]}
        simulateTouch={true}
        // preventClicks={false}
        // preventClicksPropagation={false}
        navigation={{ prevEl, nextEl }}
        // autoHeight={true}
        keyboard={{
          enabled: true,
        }}
        // pagination={{
        //   clickable: true,
        //   type: 'bullets',
        // }}
        mousewheel={{
          forceToAxis: true,
        }}
      >
        {slides?.filter(Boolean).map((slide, n) => (
          <SwiperSlide
            className={cx('self-stretch', slideClassName)}
            style={slideStyle}
            key={n}
          >
            <div className={'h-full select-none'}>{slide}</div>
          </SwiperSlide>
        ))}
      </Swiper>

      <div className="flex justify-end gap-3">
        {['prev', 'next'].map((direction) => (
          <button
            key={direction}
            type="button"
            className={cx(
              'border-2 rounded-full flex items-center justify-center w-10 h-10 transition-colors',
              controlsColorClasses[controlsColor || 'action-base'],
            )}
            ref={(node) =>
              direction === 'prev' ? setPrevEl(node) : setNextEl(node)
            }
          >
            <span className="sr-only">
              {direction === 'prev'
                ? translations?.next_slide?.[locale]
                : translations?.previous_slide?.[locale]}
            </span>
            <IconLoader
              icon="chevron"
              className={cx('w-5 h-5', {
                ['rotate-90']: direction === 'prev',
                ['-rotate-90']: direction === 'next',
              })}
            />
          </button>
        ))}
      </div>
    </div>
  );
};
